<template>
  <div class="container">

    <!--Breadcrumb 영역 s-->
    <div class="top-nav-container">
      <nav role="navigator">
        <ul class="breadcrumbs list-unstyled hide-sm">
          <li class="breadcrumb"><a href="#">제품</a></li>
          <li class="breadcrumb" v-html="getHead"/>
          <li class="breadcrumb" v-html="getTitle" @click="$router.push(getLandingPath)" role="link"/>
          <li class="breadcrumb">제품브로셔</li>
        </ul>
      </nav>
    </div>
    <!--Breadcrumb 영역 e-->

    <div class="section">

      <!--Page Title 영역 s-->
      <div class="tit_page_area">
        <h1>제품브로셔</h1>
        <p>BMS 제품 관련 다양한 브로셔, 머티리얼 정보와 파일을 제공합니다.</p>
        <!--<div class="tit_right_icon_area">
            <a href="./product_brochure_listview.html">
                <img src="@/assets/images/icon_listview.png" alt="">
            </a>
        </div>-->
      </div>
      <!--<div class="update_area">
          <span>Last Update</span>2021.04.01
      </div>-->
      <!--Page Title 영역 e-->

      <!--본문 영역 s-->
      <content-area
          v-bind="board"
          file-name="BMSON-제품브로셔"
          :bookmark-id="$route.params.brochureNo"/>
      <!--본문 영역 e-->

      <!--list view 버튼-->
      <div class="btn_area_right btn_listview">
        <router-link to='../' tag="button" append>List View</router-link>
      </div>

    </div>
  </div>
</template>

<script>
import ContentArea from "@/components/common/ContentArea";

export default {
  name: "BrochureView",
  components: {ContentArea},
  created() {
    this.initBoard()
    this.$emit('set-init-tab')
  },
  data() {
    return {
      board: {
        title: ''
      },
    }
  },
  methods: {
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.brochureNo,
        type: 'brochure'
      }).then(board => {
        this.board = board
      })
    }
  },
  props: {
  },
  computed: {
    getLandingPath() {
      return {
        name: 'Product',
        params: {
          productName : this.$route.params.productName,
        },
      }
    },
    getHead(){
      return this.$store.state.menu.productMenu[this.$route.params.productName].head
    },
    getTitle() {
      return this.$store.state.menu.productMenu[this.$route.params.productName].title
    },
  },
}
</script>

<style scoped>

</style>
