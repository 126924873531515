<template>
  <!--인쇄 영역 -->
  <div class="b_view" ref="document" id="print-area">
    <div class="b_view_tit">
      <h1 v-html="$options.filters.supTag(title)"></h1>
      <div class="list_date">
        <span v-if="date">Date</span> {{ date }}
        <span v-if="viewCount">Views</span> {{ viewCount }}
      </div>
      <shared-area
          v-if="!noSharedArea"
          :file-name="fileName"
          :bookmark-id="bookmarkId"
          :no-shared="noShared"/>
    </div>

    <div style="padding:56.25% 0 0 0;position:relative;" class="ignore-pdf" v-if="videoUrl">
      <iframe
          :src="videoUrl"
          title="video preview"
          allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
          style="position:absolute;top:0;left:0;width:100%;height:100%;border:none;"></iframe>
    </div>
    <div class="b_view_body" v-html="html" @click="$emit('click', $event)"/>
    <div class="related_contents" v-if="Array.isArray(relatedContents) && relatedContents.length !== 0">
      <h1>Related Contents</h1>
      <ul>
        <li v-for="(relatedContent, index) in relatedContents" :key="index">
          <a :href="relatedContent.redirectUrl">
            <img :src="relatedContent.thumbnailUrl" :alt="relatedContent.title">
            <p>{{ relatedContent.title }}</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!--인쇄 영역 -->
</template>

<script>


import SharedArea from "@/components/common/SharedArea";

export default {
  name: "ContentArea",
  components: {SharedArea},
  props: {
    title: {
      type: String,
    },
    date: {
      type: String,
    },
    viewCount: {
      type: Number,
    },
    html: {
      type: String,
    },
    videoUrl: {
      type: String,
      require: false,
    },
    relatedContents: {
      type: [],
      require: false,
    },
    fileName: {
      type: String,
    },
    bookmarkId: {
      type: [Number, String],
    },
    noShared: {
      type: Boolean,
      default: true,
    },
    noSharedArea: {
      type: Boolean,
      default: false,
    }
  },
  filters: {
    supTag: function (value) {
      return value.replace(/[®™]/gi, '<sup>$&</sup>');
    }
  },
}
</script>

<style scoped>
.b_view_body {
  margin: 50px 0;
}
</style>
